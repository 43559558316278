import { apiFetch } from "./siniestroService";

export const fetchFacturas = async (
  pageIndex: number = 0,
  pageSize: number = 10,
  incidentNumber?: string,
  companyId?: number,
  facturaNumber?: number,
  status?: string,
  startDate?: Date,
  endDate?: Date
) => {
  let url = `/api/facturas?page=${pageIndex + 1}&itemsPerPage=${pageSize}`;

  const queryParams = [];
  if (incidentNumber) queryParams.push(`incidentNumber=${incidentNumber}`);
  if (companyId !== undefined) queryParams.push(`companyId=${companyId}`);
  if (facturaNumber !== undefined)
    queryParams.push(`facturaNumber=${facturaNumber}`);
  if (status) queryParams.push(`status=${status}`);
  if (startDate)
    queryParams.push(`startDate=${startDate.toISOString().split("T")[0]}`);
  if (endDate)
    queryParams.push(`endDate=${endDate.toISOString().split("T")[0]}`);

  if (queryParams.length > 0) {
    url += `&${queryParams.join("&")}`;
  }

  const response = await apiFetch(url);

  if (!response.ok) {
    throw new Error(`Error ${response.status}: Failed to fetch facturas`);
  }

  const data = await response.json();
  return data;
};

export const fetchFacturaBySiniestroNumber = async (
  siniestroNumber: number
) => {
  try {
    const response = await apiFetch(
      `/api/facturas/siniestro/${siniestroNumber}`
    );

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to fetch factura by siniestro number`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const addFactura = async (facturaData: FacturaInput) => {
  try {
    const response = await apiFetch(`/api/facturas`, {
      method: "POST",
      body: JSON.stringify(facturaData),
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: Failed to add factura`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteFactura = async (id: number) => {
  try {
    const response = await apiFetch(`/api/facturas/${id}`, {
      method: "DELETE",
    });

    if (!response.ok) {
      throw new Error(`Error ${response.status}: Failed to delete factura`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const changeStatusFactura = async (id: number, status: string) => {
  try {
    const response = await apiFetch(`/api/facturas/${id}`, {
      method: "PATCH",
      body: JSON.stringify({ status }),
    });

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to change status of factura`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateStatusMultipleFacturas = async (
  facturaIds: number[],
  status: string
) => {
  console.log("facturaIds:", facturaIds);
  console.log("status:", status);
  try {
    const response = await apiFetch(`/api/facturas/facturas-status`, {
      method: "PATCH",
      body: JSON.stringify({ facturaIds, status }),
    });

    if (!response.ok) {
      throw new Error(
        `Error ${response.status}: Failed to change status of multiple facturas`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateStatusByFacturaNumbers = async (
  facturaNumbers: number[],
  newStatus: string
) => {
  console.log(
    "Actualizando facturas por números:",
    facturaNumbers,
    "a estado:",
    newStatus
  );
  try {
    const response = await apiFetch("/api/facturas/update-status-by-numbers", {
      method: "POST",
      body: JSON.stringify({ facturaNumbers, newStatus }),
    });
    const data = await response.json();
    console.log("Respuesta del servidor:", data);
    return data;
  } catch (error) {
    console.error("Error en updateStatusByFacturaNumbers:", error);
    throw error;
  }
};

export const generarFacturaC = async (
  siniestroId: number,
  detalleFactura: any[],
  compañiaAseguradora: string,
  rubro: string
) => {
  try {
    const response = await apiFetch(`/api/facturas/generar-factura`, {
      method: "POST",
      body: JSON.stringify({
        siniestroId,
        detalleFactura,
        compañiaAseguradora,
        rubro,
      }),
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Error en la respuesta del servidor");
    }
    return data;
  } catch (error) {
    console.error("Error al generar la factura C:", error);
    throw error;
  }
};
