import { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { SolarNotebookOutline } from "@/components/icons/SolarNotebookOutline";
import { useEstadoStore } from "@/stores/estadoStore";
import { toast } from "sonner";
import {
  addAccionToSiniestro,
  apiFetch,
  createNotificacionForAction,
} from "@/services/siniestroService";
import { fetchUserRoles } from "@/services/userService";

interface AddInformeTecnicoDialogProps {
  bienes: Bien[];
  onSave: (bienId: number, observaciones: string) => Promise<void>;
  siniestroId: number;
  onSiniestroUpdated: (updatedSiniestro: Partial<Siniestro>) => void;
}

export function AddInformeTecnicoDialog({
  bienes,
  onSave,
  siniestroId,
  onSiniestroUpdated,
}: AddInformeTecnicoDialogProps) {
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedBien, setSelectedBien] = useState<number | null>(null);
  const [selectedEquipo, setSelectedEquipo] = useState<string | null>(null);
  const [selectedDano, setSelectedDano] = useState<string | null>(null);
  const [tieneReparacion, setTieneReparacion] = useState<boolean | null>(null);
  const [selectedTramitador, setSelectedTramitador] = useState<number | null>(
    null
  );

  const { tramitadores, fetchEstadosYTramitadores } = useEstadoStore();

  useEffect(() => {
    void fetchEstadosYTramitadores();
  }, [fetchEstadosYTramitadores]);

  const equipos = [
    "TV",
    "PC",
    "Notebook",
    "Celular",
    "Tablet",
    "Consola de videojuegos PlayStation",
    "Consola de videojuegos XBOX",
    "Otro",
  ];
  const danos = ["Tensión", "Rayo", "Caída", "Otro"];

  const resetForm = () => {
    setStep(1);
    setSelectedBien(null);
    setSelectedEquipo(null);
    setSelectedDano(null);
    setTieneReparacion(null);
    setSelectedTramitador(null);
  };

  const getDanoDescripcion = (
    equipo: string,
    dano: string,
    tieneReparacion: boolean
  ) => {
    if (equipo === "TV") {
      if (dano === "Tensión" || dano === "Rayo") {
        return tieneReparacion
          ? "daños en la placa main y fuente de alimentación, los cuales fueron reparados mediante el reemplazo de estos componentes"
          : "daños irreparables en la placa main y fuente de alimentación";
      } else if (dano === "Caída") {
        return tieneReparacion
          ? "daños en la pantalla, la cual fue reemplazada"
          : "daños irreparables en la pantalla";
      }
    }
    return tieneReparacion
      ? "daños que fueron reparados"
      : "daños irreparables";
  };

  const handleTramitadorChange = async (newTramitadorId: number) => {
    try {
      const response = await apiFetch(
        `/api/siniestros/${siniestroId}/tramitador`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ tramitadorId: newTramitadorId }),
        }
      );

      if (!response.ok) {
        throw new Error("Error al actualizar el tramitador del siniestro");
      }

      // Obtener los roles del nuevo tramitador
      const { roles } = await fetchUserRoles(newTramitadorId);

      // Agregar la acción al siniestro
      await addAccionToSiniestro(siniestroId, 101, newTramitadorId, null);

      // Verificar si el usuario no tiene el rol de Taller (ID 12)
      if (!roles.includes(12)) {
        await createNotificacionForAction(
          newTramitadorId,
          101,
          siniestroId,
          roles[0] // Pasamos el primer rol del usuario como ejemplo
        );
      }

      const newTramitador = tramitadores.find(
        (tramitador) => tramitador.id === newTramitadorId
      );

      if (newTramitador) {
        const updatedSiniestroData = {
          id: siniestroId,
          responsible: newTramitadorId,
          responsibleName: newTramitador.display_name,
        };
        onSiniestroUpdated(updatedSiniestroData);
      }

      toast.success("Tramitador asignado correctamente.");
    } catch (error) {
      console.error("Error al actualizar el tramitador:", error);
      toast.error("Error al actualizar el tramitador del siniestro.");
    }
  };

  const handleSave = async () => {
    if (
      selectedBien &&
      selectedEquipo &&
      selectedDano !== null &&
      tieneReparacion !== null
    ) {
      const danoDescripcion = getDanoDescripcion(
        selectedEquipo,
        selectedDano,
        tieneReparacion
      );
      const informe = `Se verificó el bien siniestrado (${selectedEquipo}). El mismo presentaba ${danoDescripcion}. ${
        !tieneReparacion
          ? "No se consiguen repuestos en plaza para efectuar su reparación."
          : `Daños ocasionados por ${selectedDano.toLowerCase()}.`
      }`;

      await onSave(selectedBien, informe);

      if (selectedTramitador) {
        await handleTramitadorChange(selectedTramitador);
      }

      setOpen(false);
      resetForm();
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="mr-2">
          <SolarNotebookOutline className="w-5 h-5 mr-2" />
          Agregar informe técnico
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Agregar informe técnico</DialogTitle>
          <DialogDescription>
            Complete los siguientes pasos para generar un informe técnico.
          </DialogDescription>
        </DialogHeader>
        {step === 1 && (
          <div>
            <h4 className="mb-2">Seleccione un bien:</h4>
            <Select onValueChange={(value) => setSelectedBien(Number(value))}>
              <SelectTrigger>
                <SelectValue placeholder="Seleccionar bien" />
              </SelectTrigger>
              <SelectContent>
                {bienes.map((bien) => (
                  <SelectItem key={bien.id} value={bien.id.toString()}>
                    {bien.itemType} - {bien.brandName}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {step === 2 && (
          <div>
            <h4 className="mb-2">¿Qué equipo es?</h4>
            <Select onValueChange={setSelectedEquipo}>
              <SelectTrigger>
                <SelectValue placeholder="Seleccionar equipo" />
              </SelectTrigger>
              <SelectContent>
                {equipos.map((equipo) => (
                  <SelectItem key={equipo} value={equipo}>
                    {equipo}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {step === 3 && (
          <div>
            <h4 className="mb-2">¿Cuál es el daño?</h4>
            <Select onValueChange={setSelectedDano}>
              <SelectTrigger>
                <SelectValue placeholder="Seleccionar daño" />
              </SelectTrigger>
              <SelectContent>
                {danos.map((dano) => (
                  <SelectItem key={dano} value={dano}>
                    {dano}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        {step === 4 && (
          <div>
            <h4 className="mb-2">¿Tiene reparación?</h4>
            <div className="space-x-2">
              <Button
                onClick={() => setTieneReparacion(true)}
                variant={tieneReparacion === true ? "default" : "outline"}
              >
                Sí
              </Button>
              <Button
                onClick={() => setTieneReparacion(false)}
                variant={tieneReparacion === false ? "default" : "outline"}
              >
                No
              </Button>
            </div>
          </div>
        )}
        {step === 5 && (
          <div>
            <h4 className="mb-2">Seleccione un nuevo tramitador (opcional):</h4>
            <Select
              onValueChange={(value) => setSelectedTramitador(Number(value))}
            >
              <SelectTrigger>
                <SelectValue placeholder="Seleccionar tramitador" />
              </SelectTrigger>
              <SelectContent>
                {tramitadores.map((tramitador) => (
                  <SelectItem
                    key={tramitador.id}
                    value={tramitador.id.toString()}
                  >
                    {tramitador.display_name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
        <DialogFooter>
          {step > 1 && (
            <Button
              onClick={() => setStep((prev) => prev - 1)}
              variant="outline"
            >
              Anterior
            </Button>
          )}
          {step < 5 && (
            <Button
              onClick={() => setStep((prev) => prev + 1)}
              disabled={!selectedBien && step === 1}
            >
              Siguiente
            </Button>
          )}
          {step === 5 && (
            <Button onClick={handleSave} disabled={tieneReparacion === null}>
              Guardar informe
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
