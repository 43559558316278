import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  type ColumnFiltersState,
  type VisibilityState,
  type SortingState,
} from "@tanstack/react-table";
import { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { FacturaColumns } from "./FacturaColumns";
import { FacturasDataTablePagination } from "./FacturasDataTablePagination";
import useUserStore from "@/stores/userStore";
import Spinner from "../ui/spinner";
import {
  fetchFacturas,
  updateStatusByFacturaNumbers,
  updateStatusMultipleFacturas,
} from "@/services/facturaService"; // Asegúrate de tener esta función en tu servicio
import { Button } from "../ui/button";
import { IonSearch } from "../icons/IonSearch";
import { NumbersFCFilter } from "./filters/NumbersFCFilter";
import { CompanyFCFilter } from "./filters/CompanyFCFilter";
import { StatusFCFilter } from "./filters/StatusFCFilter";
import { DatePickerDemo } from "../ui/DatePickerDemo";
import { Input } from "../ui/input";
import { DollarSign, Users, CreditCard, Download } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { apiFetch } from "@/services/siniestroService";
import { ReloadIcon } from "@radix-ui/react-icons";
import { Skeleton } from "../ui/skeleton";
import { toast } from "sonner";

import { parseISO, startOfToday, endOfToday } from "date-fns";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { ScrollArea } from "../ui/scroll-area";

const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString || isNaN(Date.parse(dateString))) {
    return "Fecha no disponible";
  }
  const date = parseISO(dateString); // Convierte la fecha ISO 8601 a un objeto Date
  return `${String(date.getUTCDate()).padStart(2, "0")}/${String(
    date.getUTCMonth() + 1
  ).padStart(2, "0")}/${date.getUTCFullYear()}`;
};

interface DataTableProps<TData extends WithId, TValue> {
  columns: Array<ColumnDef<TData, TValue>>;
  currentPage: number;
}

export function FacturaDataTable({
  currentPage,
}: DataTableProps<Factura, unknown>) {
  const [facturas, setFacturas] = useState<Factura[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>("");
  const [inputValue, setInputValue] = useState("");
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(
    undefined
  );
  const [selectedCompanyId, setSelectedCompanyId] = useState<
    number | undefined
  >(undefined);

  const [appliedFilter, setAppliedFilter] = useState<string | undefined>("");
  const [appliedSearchTerm, setAppliedSearchTerm] = useState<
    string | undefined
  >("");
  const [appliedStatus, setAppliedStatus] = useState<string | undefined>(
    undefined
  );
  const [searchCount, setSearchCount] = useState(0);

  const [appliedCompanyId, setAppliedCompanyId] = useState<number | undefined>(
    undefined
  );

  const [startDate, setStartDate] = useState<Date>(startOfToday());
  const [endDate, setEndDate] = useState<Date>(endOfToday());
  const [appliedStartDate, setAppliedStartDate] = useState<Date | undefined>(
    undefined
  );
  const [isLoadingKpis, setIsLoadingKpis] = useState(true);
  const [appliedEndDate, setAppliedEndDate] = useState<Date | undefined>(
    undefined
  );
  const [kpis, setKpis] = useState<any>({});
  const [isExporting, setIsExporting] = useState(false);
  const [extractedNumbers, setExtractedNumbers] = useState<number[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const updateFactura = (invoiceId: number, newStatus: any) => {
    setFacturas((facturasPrev) =>
      facturasPrev.map((factura) =>
        factura.id === invoiceId ? { ...factura, status: newStatus } : factura
      )
    );
  };
  const columns = FacturaColumns(updateFactura);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = useState({});

  const [pagination, setPagination] = useState({
    pageIndex: currentPage,
    pageSize: 10,
  });

  const cargarFacturas = async () => {
    setIsLoading(true);
    setIsLoadingKpis(true);
    try {
      const response = await fetchFacturas(
        pagination.pageIndex,
        pagination.pageSize,
        appliedFilter === "siniestroNumber" ? appliedSearchTerm : undefined,
        selectedCompanyId,
        appliedFilter === "facturaNumber"
          ? Number(appliedSearchTerm)
          : undefined,
        selectedStatus,
        startDate ? new Date(startDate) : undefined,
        endDate ? new Date(endDate) : undefined
      );

      setFacturas(response.facturas);
      setPageCount(response.pageCount || 0);
      setKpis(response.kpis);
    } catch (error) {
    } finally {
      setIsLoading(false);
      setIsLoadingKpis(false);
    }
  };

  useEffect(() => {
    cargarFacturas();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    appliedFilter,
    appliedCompanyId,
    appliedStatus,
    appliedStartDate,
    appliedEndDate,
    searchCount,
  ]);

  const table = useReactTable({
    data: facturas,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: (newPagination) => {
      setPagination(newPagination);
    },
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
      pagination,
    },
    manualPagination: true,
    pageCount,
  });

  const setCurrentPage = useUserStore((state) => state.setCurrentPage);

  useEffect(() => {
    setCurrentPage(table.getState().pagination.pageIndex);
    setPagination(table.getState().pagination);
  }, [table.getState().pagination.pageIndex, setCurrentPage]);

  const numberFormatter = new Intl.NumberFormat("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 2,
  });

  const kpiFormatter = (value: number) => {
    return value;
  };

  const kpiIVAFormatter = (value: number) => {
    return value * 1.21;
  };

  const descargarExcel = async () => {
    setIsExporting(true);
    const params = new URLSearchParams();

    if (appliedFilter === "siniestroNumber" && appliedSearchTerm) {
      params.append("incidentNumber", appliedSearchTerm);
    }
    if (appliedCompanyId) {
      params.append("companyId", appliedCompanyId.toString());
    }
    if (appliedFilter === "facturaNumber" && appliedSearchTerm) {
      params.append("facturaNumber", appliedSearchTerm);
    }
    if (appliedStatus) {
      params.append("status", appliedStatus);
    }
    if (appliedStartDate) {
      params.append("startDate", appliedStartDate.toISOString().split("T")[0]);
    }
    if (appliedEndDate) {
      params.append("endDate", appliedEndDate.toISOString().split("T")[0]);
    }

    try {
      const response = await apiFetch(
        `/api/facturas/download?${params.toString()}`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error("Error al descargar el archivo");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "facturas.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
    } finally {
      setIsExporting(false);
    }
  };

  const handleBuscarClick = () => {
    // Actualiza los estados "applied" al hacer clic en el botón
    setAppliedFilter(selectedFilter);
    setAppliedSearchTerm(inputValue);
    setAppliedStatus(selectedStatus);
    setAppliedCompanyId(selectedCompanyId);
    setAppliedStartDate(startDate);
    setAppliedEndDate(endDate);

    setSearchCount(searchCount + 1); // Incrementar el contador

    // Reiniciar la paginación al realizar una nueva búsqueda
    setPagination((prev) => ({ ...prev, pageIndex: 0 }));
  };

  const handleUpdateStatusClick = async (status: string) => {
    const selectedIds = table
      .getSelectedRowModel()
      .rows.map((row) => row.original.id);
    try {
      const updatedFacturas = await updateStatusMultipleFacturas(
        selectedIds,
        status
      );

      setFacturas((prev) =>
        prev.map((factura) => {
          const updatedFactura = updatedFacturas.find(
            (uf: { id: number }) => uf.id === factura.id
          );
          return updatedFactura ? { ...factura, ...updatedFactura } : factura;
        })
      );
      toast.success("Estado actualizado con éxito");
    } catch (error) {
      toast.error("Error al actualizar el estado");
    }
  };
  const actualizarEstadoFacturasDesdeArchivo = async (file: File) => {
    const texto = await file.text();
    console.log("Texto completo:", texto);
    const lineas = texto.split("\n").filter((linea) => linea.trim() !== "");

    const numerosFactura = lineas
      .map((linea) => {
        console.log("Procesando línea:", linea);
        const campos = linea.split(",");
        if (campos.length >= 2 && campos[1].includes("A")) {
          const numeroCompleto = campos[1].replace(/"/g, "");
          console.log("Número completo:", numeroCompleto);
          // Dividir el número en sus componentes
          const match = numeroCompleto.match(/A(\d{5})(\d+)$/);
          if (match) {
            const [, , numeroFactura] = match;
            // Eliminar ceros a la izquierda del número de factura
            const numeroSinCeros = numeroFactura.replace(/^0+/, "");
            const resultado = parseInt(numeroSinCeros);
            console.log("Resultado:", resultado);
            return resultado;
          }
        }
        return null;
      })
      .filter((numero): numero is number => numero !== null);

    console.log("Números de factura extraídos:", numerosFactura);
    setExtractedNumbers(numerosFactura);
    setIsDialogOpen(true);
  };
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      actualizarEstadoFacturasDesdeArchivo(file);
      // Resetear el valor del input de archivo
      event.target.value = "";
    }
  };

  const handleConfirmUpdate = async () => {
    try {
      console.log("Números de factura a actualizar:", extractedNumbers);
      const resultado = await updateStatusByFacturaNumbers(
        extractedNumbers,
        "P"
      );
      console.log("Resultado de la actualización:", resultado);

      if (resultado.updatedFacturas && resultado.updatedFacturas.length > 0) {
        setFacturas((prev) => {
          const nuevasFacturas = prev.map((factura) => {
            const updatedFactura = resultado.updatedFacturas.find(
              (uf: { number: number }) => uf.number === factura.number
            );
            if (updatedFactura) {
              console.log(
                "Factura actualizada:",
                factura.number,
                "->",
                updatedFactura.status
              );
              return { ...factura, ...updatedFactura };
            }
            return factura;
          });
          console.log("Nuevas facturas:", nuevasFacturas);
          return nuevasFacturas;
        });

        toast.success(
          `${resultado.updatedFacturas.length} facturas marcadas como pagadas con éxito`
        );
      }

      if (resultado.notFoundFacturas && resultado.notFoundFacturas.length > 0) {
        toast.warning(
          `No se encontraron las siguientes facturas: ${resultado.notFoundFacturas.join(
            ", "
          )}`
        );
      }

      await cargarFacturas();
    } catch (error) {
      console.error("Error al actualizar el estado de las facturas:", error);
      toast.error("Error al actualizar el estado de las facturas");
    } finally {
      setIsDialogOpen(false);
      setExtractedNumbers([]);
    }
  };

  return (
    <>
      <div className="py-6">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex flex-col md:flex-row md:flex-1 md:items-center md:space-x-2">
            <Input
              placeholder="Buscar..."
              value={inputValue}
              className="w-[180px] md:max-w-sm mb-2 md:mb-0"
              onChange={(e) => setInputValue(e.target.value.trim())}
            />
            <NumbersFCFilter
              value={selectedFilter}
              onChange={(value) => setSelectedFilter(value)}
            />
            <CompanyFCFilter
              onChange={(value) =>
                setSelectedCompanyId(
                  value === "Todas" ? undefined : Number(value)
                )
              }
              selectedValue={selectedCompanyId?.toString() || "Todas"}
            />
            <StatusFCFilter
              value={selectedStatus}
              onChange={(value) => {
                setSelectedStatus(value);
              }}
            />
            <DatePickerDemo
              date={startDate}
              onChange={(date) => {
                if (date && endDate && date > endDate) {
                  // Si la nueva fecha de inicio es posterior a la fecha de fin, no hacer nada
                  return;
                }
                if (date) {
                  setStartDate(date);
                }
              }}
              label="Fecha de inicio"
            />
            <DatePickerDemo
              date={endDate}
              onChange={(date) => {
                if (date && startDate && date < startDate) {
                  toast.error(
                    "La fecha de fin no puede ser anterior a la fecha de inicio"
                  );
                  return;
                }
                if (date) {
                  setEndDate(date);
                }
              }}
              label="Fecha de fin"
            />
            <Button onClick={handleBuscarClick}>
              <IonSearch />
            </Button>

            <Button
              onClick={descargarExcel}
              variant="green"
              className="ml-2 md:ml-0 text-white text-sm flex items-center justify-center space-x-2 w-auto whitespace-nowrap overflow-hidden text-ellipsis"
              disabled={isExporting}
            >
              {isExporting ? (
                <>
                  <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                  Exportando...
                </>
              ) : (
                <>
                  <Download className="h-4 w-4" />
                  <span>Exportar Excel</span>
                </>
              )}
            </Button>
          </div>
        </div>
      </div>

      <div className="py-6 grid grid-cols-1 gap-4 lg:grid-cols-2 xl:grid-cols-4">
        {isLoadingKpis ? (
          <>
            <Skeleton className="h-32 w-full rounded-md" />
            <Skeleton className="h-32 w-full rounded-md" />
            <Skeleton className="h-32 w-full rounded-md" />
            <Skeleton className="h-32 w-full rounded-md" />
            <Skeleton className="h-32 w-full rounded-md" />
          </>
        ) : (
          <>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Total facturas
                </CardTitle>
                <CreditCard className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{kpis.totalFacturas}</div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Facturas pendientes
                </CardTitle>
                <Users className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {kpis.facturasPendientes}
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Facturas pagadas
                </CardTitle>
                <DollarSign className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">{kpis.facturasPagadas}</div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Monto total
                </CardTitle>
                <DollarSign className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {numberFormatter.format(kpiFormatter(kpis.totalAmount))}
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Monto total + IVA
                </CardTitle>
                <DollarSign className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">
                  {numberFormatter.format(kpiIVAFormatter(kpis.totalAmount))}
                </div>
              </CardContent>
            </Card>
          </>
        )}
      </div>

      <div className="flex space-x-2 mb-4">
        <Button
          variant="destructive"
          onClick={() => handleUpdateStatusClick("N")}
          disabled={table.getFilteredSelectedRowModel().rows.length === 0}
        >
          Marcar como pendiente
        </Button>
        <Button
          variant="green"
          onClick={() => handleUpdateStatusClick("P")}
          disabled={table.getFilteredSelectedRowModel().rows.length === 0}
        >
          Marcar como pagado
        </Button>

        <input
          type="file"
          accept=".txt,.csv"
          onChange={handleFileUpload}
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <Button
          onClick={() => {
            if (fileInputRef.current) {
              fileInputRef.current.value = ""; // Resetear el valor antes de abrir el diálogo de selección de archivo
              fileInputRef.current.click();
            }
          }}
        >
          Cargar archivo de facturas
        </Button>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirmar actualización de facturas</DialogTitle>
            <DialogDescription>
              Se marcarán como pagadas las siguientes facturas:
            </DialogDescription>
          </DialogHeader>
          <ScrollArea className="h-[200px] w-full rounded-md border p-4">
            <ul>
              {extractedNumbers.map((numero, index) => (
                <li key={index}>{numero}</li>
              ))}
            </ul>
          </ScrollArea>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => {
                setIsDialogOpen(false);
                setExtractedNumbers([]);
              }}
            >
              Cancelar
            </Button>
            <Button onClick={handleConfirmUpdate}>
              Confirmar y actualizar
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <div className="rounded-md border overflow-x-auto">
        <Table key={pagination.pageIndex}>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup, groupIndex) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header, headerIndex) => (
                  <TableHead key={`${groupIndex}_${headerIndex}`}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  <Spinner />
                </TableCell>
              </TableRow>
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, rowIndex) => (
                <TableRow
                  key={row.original.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <TableCell key={`${rowIndex}_${cellIndex}`}>
                      {cell.column.id === "date" ||
                      cell.column.id === "insertedAt"
                        ? formatDate(cell.getValue() as string)
                        : flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No se han encontrado facturas con los criterios de búsqueda
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} de{" "}
          {table.getFilteredRowModel().rows.length} fila(s) seleccionadas.
        </div>
      </div>
      <FacturasDataTablePagination table={table} pageCount={pageCount} />
    </>
  );
}
